<script setup>
import NavBar from '@/components/NavBar'
import Header from '@/components/HeaderComponent'
import Overview from '@/components/Overview'
import Registration from '@/components/Registration'
import Contact from '@/components/Contact'
import Footer from '@/components/Footer'
import Submission from '@/components/Submission'
import Scheduler from '@/components/Scheduler'
import Organizers from '@/components/Organizers'
import Speakers from '@/components/Speakers'
import Sponsors from '@/components/Sponsors'
import AgendaDetails from '@/components/AgendaDetails.vue'
import {inject} from 'vue'
import Award from '@/components/Award'
import RegistrationPht from '@/components/RegistrationPht.vue'
import CallForAbstracts from '@/components/CallForAbstracts.vue'

const contentData = inject('contentData')

const mainTitle = document.getElementById('mainTitle')
const favicon = document.getElementById('favicon')
if (contentData.what === 'rn') {
    mainTitle.innerHTML = 'Swiss Reproducibility Conference 2024'
    favicon.href = '<%= BASE_URL %>iconRN.ico"'
}
</script>

<template>
    <div>
        <NavBar />
        <Header />
        <Overview v-if="contentData.overview.display" />
        <Scheduler v-if="contentData.sessions.display && contentData.what === 'rn'" />
        <AgendaDetails v-if="contentData.sessions.display && contentData.what === 'rn'" />
        <Award v-if="contentData.award.display" />
        <Speakers v-if="contentData.speakers.display" />
        <AgendaDetails v-if="contentData.sessions.display && contentData.what === 'pht'" />
        <Scheduler v-if="contentData.sessions.display && contentData.what === 'pht'" />
        <Registration v-if="contentData.registration.display && contentData.what === 'rn'" />
        <RegistrationPht v-if="contentData.registration.display && contentData.what === 'pht'" />
        <Submission v-if="contentData.submission.display" />
        <Sponsors v-if="contentData.sponsors.display" />
        <CallForAbstracts v-if="contentData.what === 'rn'" />
        <Organizers />

        <Contact />
        <Footer />
    </div>
</template>

<style scoped></style>
