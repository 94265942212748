export const filterEvents = (day, time, events) => {
    return events.filter(item => item.ref === `${time} ${day}`)
}

export const getTimes = events => {
    return events.reduce((refs, ev) => {
        ev.time.forEach(time => refs.push(`${ev.day} ${time}`))
        return refs
    }, [])
}
