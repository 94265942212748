<script setup>
import axios from 'axios'
import {onMounted, ref} from 'vue'
import {useRoute} from 'vue-router'
import NavBar from '@/components/NavBar'

const eventsURL = 'api/events'
const backEndURL = process.env.VUE_APP_BACKEND_URL
const events = ref([])
const currentEvent = ref(null)

const route = useRoute()

onMounted(async () => {
    events.value = await getEvents()
    await parseData()
})

const getEvents = async () => {
    try {
        const res = await axios.get(`${backEndURL}/${eventsURL}`)
        console.log('', res)
        return res.data
    } catch (err) {
        console.error(err)
    }
}

const parseData = async () => {
    const currentRoute = route.params.link
    currentEvent.value = events.value.find(item => item.link === currentRoute)
}
</script>

<template>
    <div>
        <NavBar />
        <section class="py-16" id="registration">
            <div class="container px-4 mx-auto max-w-3xl" v-if="currentEvent">
                <div class="max-w-3xl mx-auto text-center">
                    <h1 class="font-heading text-3xl sm:text-4xl mt-2 mb-6">
                        {{ currentEvent.title }}
                    </h1>
                </div>

                <div
                    class="text-justify"
                    v-if="currentEvent.additional_info"
                    v-html="currentEvent.additional_info"></div>
            </div>
        </section>
    </div>
</template>

<style scoped></style>
