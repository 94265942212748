<script setup>
import {inject} from 'vue'
const contentData = inject('contentData')

let registrationData = {
    comment: 'Registration for the Swiss Reproducibility Conference 2024 is closed.',
    details:
        'The registration fee covers all conference activities (scientific talks, coffee breaks, lunches, award ceremony and apéro riche, workshops/hackathon) held during 10th and 11th June 2024 as indicated in the conference program. When registering, you have to choose in which optional activities (award ceremony, workshops etc.) you will participate in. The number of participants in the workshops and the hackathon is limited. So book your slot early.',
    earlyBird: {
        deadline: '31.3.2024',
        categories: [
            {type: 'Student / Retired', fee: 'CHF 80'},
            {type: 'Academic / Non-profit / Government', fee: 'CHF 160'},
            {type: 'Corporate', fee: 'CHF 250'},
        ],
    },
    regularRegistration: {
        period: '1.4.2024 – 27.5.2024',
        categories: [
            {type: 'Student / Retired', fee: 'CHF 100'},
            {type: 'Academic / Non-profit / Government', fee: 'CHF 180'},
            {type: 'Corporate', fee: 'CHF 300'},
        ],
    },
}
</script>

<template>
    <section
        class="py-16"
        id="registration"
        :class="contentData.what === 'rn' ? 'bg-gray-50' : 'bg-gray-700 text-grey-50'">
        <div class="container px-4 mx-auto max-w-3xl text-lg">
            <div class="max-w-3xl mx-auto text-center">
                <h1 class="font-heading text-3xl sm:text-4xl mt-2 mb-6">
                    {{ contentData.registration.title }}
                </h1>
            </div>

            <div class="text-justify">
                <span>
                    <b>{{ registrationData.comment }}</b>
                </span>
                <br />
                <br />
                <p>{{ registrationData.details }}</p>
                <h2 class="mt-10">
                    <b>Early Bird Registration until {{ registrationData.earlyBird.deadline }}</b>
                </h2>
                <table>
                    <tr>
                        <th>Type</th>
                        <th>Fee</th>
                    </tr>
                    <tr v-for="category in registrationData.earlyBird.categories" :key="category.type">
                        <td>{{ category.type }}</td>
                        <td>{{ category.fee }}</td>
                    </tr>
                </table>

                <h2 class="mt-10">
                    <b>Regular Registration: {{ registrationData.regularRegistration.period }}</b>
                </h2>
                <table>
                    <tr>
                        <th>Type</th>
                        <th>Fee</th>
                    </tr>
                    <tr
                        v-for="category in registrationData.regularRegistration.categories"
                        :key="category.type">
                        <td>{{ category.type }}</td>
                        <td>{{ category.fee }}</td>
                    </tr>
                </table>
            </div>
        </div>
    </section>
</template>

<style scoped>
.scroll {
    overflow: auto;
}

.scroll::-webkit-scrollbar {
    margin-top: 2px;
    width: 12px;
}

.scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

.scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.comment {
    line-height: 1.4rem;
    font-size: 1.03rem;
    margin-bottom: 40px;
}
</style>
