export const sessions = {
    title: 'Sessions',
    listItems: [
        'Advanced Technologies for 3D-Cell Systems',
        'Genomic Medicine',
        'Innovative Approaches for Drug Discovery',
        'Interoperable Decision Support',
        'Poster',
    ],
}

export const booth_packages = [
    {title: 'Platinum Booth (Number 1 or 2)', price: '2000'},
    {title: 'Gold Booth (Number 3 or 4)', price: '1750'},
    {title: 'Silver Booth (Number 5 - 8)', price: '1500'},
    {title: 'Bronze Booth (Number 9 - 13)', price: '1250'},
]

export const booth_info = [
    {
        title: 'All Booth packages include',
        items: [
            '2 free conference passes for Booth personnel',
            'Logo in the conference booklet, on the website and on the sponsor acknowledgment board in the registration area',
            'Electricity and WLAN',
            'Furniture: Each booth includes one table, a table cover, two chairs and two white exhibition' +
                'walls behind the booth',
        ],
    },
]

export const sponsorship_options = [
    {
        title: 'Exhibition Booth',
        booked: true,
        price: '1250',
        text: '',
        items: [
            '2 free conference passes for Booth personnel',
            'Logo in the conference booklet, on the website and on the sponsor acknowledgment board in the registration area',
            'Electricity and WLAN',
            'Furniture: Each booth includes one table, a table cover, two chairs and two white exhibition walls behind the booth',
        ],
    },
    {
        title: 'Main sponsor of the conference (only 1 more spot available)',
        booked: true,
        price: '5000',
        text: '',
        items: [
            'Logo on the conference website landing page (top view of website)',
            'Announcement of partnership in social media and promotional emails',
            'Opportunity to present your organization in between sessions (10 min)',
            'Visual acknowledgment on conference break slides',
            'Logo and text in the conference booklet and on the conference webpage',
            'Logo on the sponsor acknowledgment board in registration area',
            '2 free conference passes',
        ],
    },
    {
        title: 'Lanyard sponsor',
        booked: true,
        price: '1000',
        text:
            'Send us your lanyards with your logo. All participants and speakers will be wearing' +
            ' them during the conference.',
        items: [],
    },
    {
        title: 'Best Poster Award',
        booked: true,
        price: '200-400',
        text:
            'During the poster session a delegation will assess the quality of the presented posters.' +
            'The best researcher will receive a poster award at the closing ceremony of the' +
            'conference. This award can also be sponsored and should include:',
        items: [
            'Certificate',
            'Cash prize money (200-400 CHF)',
            'Handover by company official at closing event',
        ],
    },
    {
        title: 'Apéro Sponsor (Poster Session & Networking)',
        booked: true,
        price: '3000',
        text: 'After the first day, we are holding an apéro during the Poster Session.',
        items: [
            'Acknowledgement as an apéro sponsor in the conference booklet and on' + ' the website.',
            'Announcement of partnership in social media and promotional emails',
            'Logo on sponsor acknowledgement board in registration area',
            'Visual acknowledgment on conference break slides',
            '1 free conference pass',
        ],
    },
]

// const schedule = {
//     days: ['Tue 7', 'Wed 8'],
//     times: [
//         '8:30',
//         '9:00',
//         '9:30',
//         '10:00',
//         '10:30',
//         '11:00',
//         '11:30',
//         '12:00',
//         '12:30',
//         '13:00',
//         '13:30',
//         '14:00',
//         '14:30',
//         '15:00',
//         '15:30',
//         '16:00',
//         '16:30',
//         '17:00',
//         '17:30',
//         '18:00',
//         '18:30',
//         '19:00',
//         '19:30',
//         '20:00',
//         '20:30',
//         '21:00',
//         '21:30',
//         '22:00',
//     ],
//     events: [
//         {
//             parallel: false,
//             title: 'Arrival & Registration',
//             time: ['8:30'],
//             session: 'Misc',
//             day: 'Tue 7',
//             ref: '8:30 Tue 7',
//         },
//         {
//             parallel: false,
//             title: 'Welcome Address',
//             time: ['9:00'],
//             session: 'Misc',
//             day: 'Tue 7',
//             ref: '9:00 Tue 7',
//         },
//         {
//             parallel: false,
//             title: 'KEYNOTE',
//             time: ['9:30', '10:00'],
//             session: 'Advanced Technologies for 3D-Cell Systems',
//             author: ['Matthias Lütolf, Roche ITB'],
//             topic: 'Engineering next-generation tumoroids for precision medicine',
//             day: 'Tue 7',
//             ref: '9:30 Tue 7',
//         },
//         {
//             parallel: false,
//             title: 'Coffee break & Exhibition',
//             time: ['10:30'],
//             session: 'Misc',
//             day: 'Tue 7',
//             ref: '10:30 Tue 7',
//         },
//         {
//             parallel: true,
//             title: 'Contributed Talk',
//             time: ['11:00'],
//             session: 'Advanced Technologies for 3D-Cell Systems',
//             author: ['Thomas Valentin, CSEM SA'],
//             topic: 'Automated and Standardized Solutions for Organoids and Organ-on-Chips',
//             day: 'Tue 7',
//             ref: '11:00 Tue 7',
//         },
//         {
//             parallel: true,
//             title: 'Contributed Talk',
//             time: ['11:30'],
//             session: 'Advanced Technologies for 3D-Cell Systems',
//             author: ['Francisco Verdeguer, InSphero'],
//             topic: 'Modelling biological mechanisms of a PNPLA3 polymorphism in a 3D human liver microtissue for NASH progression and drug efficacy',
//             day: 'Tue 7',
//             ref: '11:30 Tue 7',
//         },
//         {
//             parallel: true,
//             title: 'PARALLEL SESSION',
//             time: ['11:00', '11:30'],
//             session: 'other',
//             author: ['Valérie Barbié, SIB', 'Mark Ibberson, SIB', 'Hartmut Schultze, HPE'],
//             topic: 'Maximising the impact of clinical data in the future',
//
//             ref: '11:00 Tue 7',
//         },
//         {
//             parallel: false,
//             title: 'Lunch & Exhibition',
//             time: ['12:00', '12:30'],
//             day: 'Tue 7',
//             session: 'Misc',
//             ref: '12:00 Tue 7',
//         },
//         {
//             parallel: false,
//             title: 'KEYNOTE',
//             time: ['13:00', '13:30'],
//             day: 'Tue 7',
//             session: 'Genomic Medicine',
//             author: ['Elisabetta Mereu, IJC'],
//             topic: 'Single-cell multi-omics: from reference atlas to human diseases',
//             ref: '13:00 Tue 7',
//         },
//
//         {
//             parallel: false,
//             title: 'Coffee break & Exhibition',
//             time: ['14:00'],
//             session: 'Misc',
//             day: 'Tue 7',
//             ref: '14:00 Tue 7',
//         },
//
//         {
//             parallel: true,
//             title: 'Contributed Talk',
//             time: ['14:30'],
//             day: 'Tue 7',
//             session: 'Genomic Medicine',
//             author: ['Michal Grzmil, Paul Scherrer Institute'],
//             topic: 'Dissecting signaling network in response to alpha particle therapy with radiolabeled minigastrin analogue [225Ac]Ac-PP-F11N reveals radiosensitizing strategies in CCKBR-positive cancers',
//             ref: '14:30 Tue 7',
//         },
//         {
//             parallel: true,
//             title: 'Contributed Talk',
//             time: ['15:00'],
//             day: 'Tue 7',
//             session: 'Genomic Medicine',
//             author: ['Laurie Prelot, ETH Zurich'],
//             topic: 'On the Best Strategy for Generation of Personalized Neoantigens for Cancer Immunotherapy',
//             ref: '15:00 Tue 7',
//         },
//
//         {
//             parallel: true,
//             title: 'PARALLEL SESSION',
//             time: ['14:30', '15:00', '15:30'],
//             day: 'Tue 7',
//             session: 'other',
//             author: ['Sabine Österle, SIB', 'Katrin Crameri, SIB', 'Julia Maurer, SIB'],
//             topic: 'From routine clinical data to FAIR research data',
//             ref: '14:30 Tue 7',
//         },
//         {
//             parallel: false,
//             title: 'Poster Session & Exhibition',
//             time: ['16:00', '16:30', '17:00'],
//             day: 'Tue 7',
//             session: 'Misc',
//             ref: '16:00 Tue 7',
//         },
//         {
//             parallel: false,
//             title: 'Arrival & Drinks',
//             time: ['18:30'],
//             day: 'Tue 7',
//             session: 'Misc',
//             ref: '18:30 Tue 7',
//         },
//         {
//             parallel: false,
//             title: 'Conference Dinner',
//             time: ['19:00', '19:30', '20:00', '20:30', '21:00', '21:30'],
//             day: 'Tue 7',
//             session: 'Misc',
//             location: 'Zunfthaus zur Saffran',
//             link: 'https://www.zunfthauszursaffran.ch',
//             ref: '19:00 Tue 7',
//         },
//         {
//             parallel: false,
//             title: 'Arrival, Coffee & Registration',
//             time: ['8:30', '9:30'],
//             session: 'Misc',
//             day: 'Wed 8',
//             ref: '8:30 Wed 8',
//         },
//         {
//             parallel: false,
//             title: 'KEYNOTE',
//             time: ['9:30', '10:00'],
//             day: 'Wed 8',
//             author: ['Erik Danen, Leiden University'],
//             session: 'Innovative Approaches for Drug Discovery',
//             topic: 'A versatile medium-throughput 3D microtissue platform for drug (target) discovery in fibrosis and (immune) oncology',
//             ref: '9:30 Wed 8',
//         },
//
//         {
//             parallel: false,
//             title: 'Coffee break & Exhibition',
//             time: ['10:30'],
//             day: 'Wed 8',
//             session: 'Misc',
//             ref: '10:30 Wed 8',
//         },
//
//         {
//             parallel: true,
//             title: 'Contributed Talk',
//             time: ['11:00'],
//             day: 'Wed 8',
//             session: 'Innovative Approaches for Drug Discovery',
//
//             author: ['Mauro Miquel Masiero, ETH Zurich'],
//             topic: 'A Map of Drug-Metabolite Interactions In Cancer Cells',
//             ref: '11:00 Wed 8',
//         },
//         {
//             parallel: true,
//             title: 'PARALLEL SESSION',
//             time: ['11:00', '11:30'],
//             day: 'Wed 8',
//             session: 'other',
//             author: ['Andreas Wicki, USZ'],
//             topic: 'Tumor Profiler: -omics data for science and clinical prediction in cancer care',
//             ref: '11:00 Wed 8',
//         },
//         {
//             parallel: true,
//             title: 'Contributed Talk',
//             time: ['11:30'],
//             day: 'Wed 8',
//             session: 'Innovative Approaches for Drug Discovery',
//             author: ['Claudio Thoma, Novartis'],
//             topic: 'Driving Drug Discovery by accelerated target protein degradation with molecular glue screens',
//             ref: '11:30 Wed 8',
//         },
//         {
//             parallel: false,
//             title: 'Lunch & Exhibition',
//             time: ['12:00', '12:30'],
//             day: 'Wed 8',
//             session: 'Misc',
//             ref: '12:00 Wed 8',
//         },
//         {
//             parallel: true,
//             title: 'Contributed Talk',
//             time: ['13:00'],
//             day: 'Wed 8',
//             session: 'Interoperable Decision Support',
//             author: ['Robert Zboray, Empa'],
//             topic: '3D non-invasive tissue and multi-omics analysis of thyroid tumors for precision medicine',
//             ref: '13:00 Wed 8',
//         },
//         {
//             parallel: true,
//             title: 'Contributed Talk',
//             time: ['13:30'],
//             day: 'Wed 8',
//             session: 'Interoperable Decision Support',
//             author: ['Leopold Franz, ETH Zurich'],
//             topic: 'Optimizing IVF Outcome with a Personalised Medicine Approach',
//             ref: '13:30 Wed 8',
//         },
//         {
//             parallel: true,
//             title: 'PARALLEL SESSION',
//             time: ['13:00', '13:30'],
//             day: 'Wed 8',
//             session: 'other',
//             author: ['Simon Häfliger, Insel'],
//             topic: 'Between excitement and frustration: an oncologist’s view of data streams in clinical practice',
//             ref: '13:00 Wed 8',
//         },
//         {
//             parallel: true,
//             title: 'PARALLEL SESSION',
//             time: ['13:30'],
//             day: 'Wed 8',
//             session: 'other',
//             author: ['Michael Krauthammer, USZ'],
//             topic: 'Building a Rapid Learning Data Infrastructure for Oncology',
//             // ref: '13:30 Wed 8',
//         },
//         {
//             parallel: false,
//             title: 'Coffee break & Exhibition',
//             time: ['14:00'],
//             session: 'Misc',
//             day: 'Wed 8',
//             ref: '14:00 Wed 8',
//         },
//         {
//             parallel: false,
//             title: 'KEYNOTE',
//             time: ['14:30', '15:00'],
//             session: 'Interoperable Decision Support',
//             author: ['Andreas Keller, Saarland University'],
//             topic: 'Understanding gene expression in aeging and neurodegeneration at single cell resolution',
//             day: 'Wed 8',
//             ref: '14:30 Wed 8',
//         },
//     ],
// }
