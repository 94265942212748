<script setup>
import {inject} from 'vue'
const contentData = inject('contentData')
</script>

<template>
    <div class="container px-4 mx-auto">
        <div class="flex flex-wrap items-center -mx-4 pb-24 sm:pb-34 justify-center">
            <div class="w-full lg:w-1/2 px-4 mb-6 lg:mb-0 flex flex-wrap justify-center items-center">
                <div
                    :class="contentData.what === 'rn' ? 'bg-gray-900' : 'bg-red-900'"
                    class="max-w-md lg:max-w-none backdrop-blur backdrop-filter bg-opacity-60 p-10 rounded-md">
                    <h1 class="font-heading text-4xl lg:text-5xl text-white">
                        {{ contentData.header.title }}
                    </h1>
                    <p
                        class="text-lg leading-8 text-white mt-2"
                        v-for="p in contentData.header.text"
                        :key="p">
                        {{ p }}
                    </p>
                </div>

                <!--                <div-->
                <!--                    v-if="contentData.what === 'rn'"-->
                <!--                    class="w-full flex flex-wrap justify-around backdrop-blur-3xl backdrop-filter p-4 rounded-lg mt-60">-->
                <!--                    <div class="inline-block">-->
                <!--                        <a-->
                <!--                            href="https://www.snf.ch/en"-->
                <!--                            v-if="contentData.first_logo !== '../assets/rn/SNF_logo.png'">-->
                <!--                            <img src="../assets/banner.png" alt="The logo image of ETHZ" />-->
                <!--                        </a>-->
                <!--                    </div>-->
                <!--                </div>-->

                <div
                    v-if="contentData.what !== 'rn'"
                    :class="contentData.what === 'rn' ? 'bg-gray-900' : 'bg-red-900'"
                    class="w-full flex flex-wrap justify-around backdrop-blur backdrop-filter bg-opacity-60 p-4 rounded-lg mt-40">
                    <div class="inline-block">
                        <a
                            href="https://www.ethz.ch/"
                            v-if="contentData.first_logo !== '../assets/rn/SNF_logo.png'">
                            <img
                                class="header-logo-2"
                                :src="contentData.first_logo"
                                alt="The logo image of ETHZ" />
                        </a>
                        <a v-else>
                            <img
                                class="header-logo-2"
                                :src="`${contentData.first_logo}`"
                                alt="The logo image of ETHZ" />
                        </a>
                    </div>

                    <div class="inline-block sm:mt-2">
                        <a href="https://www.nexus.ethz.ch/">
                            <img
                                class="header-logo-1"
                                :src="`${contentData.second_logo}`"
                                alt="The
                                logo image" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.header-logo-2 {
    height: 5.5vh;
    margin-top: 2px;
}

.header-logo-1 {
    height: 5.5vh;
}
</style>
