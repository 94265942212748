<script setup>
import {onMounted, ref, inject} from 'vue'
import axios from 'axios'

onMounted(async () => {
    await getOrganizers(organizersUrl)
})

const contentData = inject('contentData')
const backEndURL = process.env.VUE_APP_BACKEND_URL
const title = 'Organizers'
const organizers = ref([])
const organizersUrl = 'api/organizers'

const getOrganizers = async url => {
    try {
        const response = await axios.get(`${backEndURL}/${url}`)
        organizers.value = response.data
    } catch (err) {
        console.log(err)
    }
}
</script>

<template>
    <section class="py-16 bg-gray-50">
        <div class="container px-4 mx-auto">
            <div class="max-w-3xl mx-auto text-center">
                <h1 class="font-heading text-3xl sm:text-4xl mt-2 mb-6 uppercase">
                    {{ $t('organizers.title') }}
                </h1>
            </div>
            <div class="flex flex-wrap -mx-4 -mb-8 justify-evenly">
                <div class="w-full sm:w-1/2 xl:w-1/3 px-4 mb-8" v-for="organizer in organizers">
                    <div class="pt-10 pb-8 px-6 text-center bg-white rounded-lg">
                        <img
                            class="block mb-8 mx-auto rounded-full object-scale-down h-36 w-38"
                            :src="organizer.image ? organizer.image : require('@/assets/placeholder.jpg')"
                            :alt="organizer.name" />
                        <h4 class="font-heading leading-7 text-lg font-medium">{{ organizer.name }}</h4>
                        <p class="leading-7 text-gray-400" v-for="role in organizer.roles">
                            {{ role }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped></style>
