<script setup>
import {ref} from 'vue'

const registrationData = ref({
    comment: 'Registration to the Personalized Health Technology Conference 2024 is now open. ',
    details:
        'Conference Fee includes: Access to all on-site sessions, coffee breaks, lunch buffet and apero during the poster session.',
    earlyBird: {
        deadline: '31.05.2024',
        categories: [
            {type: 'Corporate', fee: 'CHF 450'},
            {type: 'Academic/Non-profit/Government', fee: 'CHF 195'},
            {type: 'Student/Retired', fee: 'CHF 90'},
        ],
    },
    standardRegistration: {
        period: 'until 31.07.2024',
        categories: [
            {type: 'Corporate', fee: 'CHF 600'},
            {type: 'Academic/Non-profit/Government', fee: 'CHF 260'},
            {type: 'Student/Retired', fee: 'CHF 120'},
        ],
    },
    workshops: {
        fee: 'CHF 40',
        details: 'Workshop Fee includes: access to one of the five workshops, coffee breaks and lunch.',
    },
    socialEvents: {
        conferenceDinner: {
            attendee: 'CHF 100',
            accompanyingPerson: 'CHF 100',
        },
        details: '',
    },
    paymentOptions: 'We accept only online payments (credit card, twint, ...)',
    cancellationPolicy:
        'Unfortunately, we will not be able to refund the registrations fees. If a registered attendee is unable to attend the Conference, their registration may be transferred to another person who is not already registered for the Conference at the same institution or organization at no additional charge.',
})
</script>

<template>
    <section class="py-16 bg-gray-700" id="registration">
        <div class="container px-4 mx-auto max-w-3xl text-lg">
            <div class="max-w-3xl mx-auto text-center">
                <h1 class="font-heading text-3xl sm:text-4xl mt-2 mb-6 text-white">Registration</h1>
            </div>
            <div class="text-gray-300 text-justify">
                <span>
                    <b>{{ registrationData.comment }}</b>
                    <a
                        class="cursor-pointer text-blue-300"
                        href="https://ethzurich.eventsair.com/pht-2024/registration/Site/Register"
                        target="_blank">
                        Register here!
                    </a>
                </span>
                <br />
                <br />
                <p>{{ registrationData.details }}</p>
                <h2 class="mt-10">
                    <b>Early Bird Registration until {{ registrationData.earlyBird.deadline }}</b>
                </h2>
                <table>
                    <tr>
                        <th>Type</th>
                        <th>Early Bird Fee</th>
                    </tr>
                    <tr v-for="category in registrationData.earlyBird.categories" :key="category.type">
                        <td>{{ category.type }}</td>
                        <td>{{ category.fee }}</td>
                    </tr>
                </table>
                <h2 class="mt-10">
                    <b>Standard Registration: {{ registrationData.standardRegistration.period }}</b>
                </h2>
                <table>
                    <tr>
                        <th>Type</th>
                        <th>Standard Fee</th>
                    </tr>
                    <tr
                        v-for="category in registrationData.standardRegistration.categories"
                        :key="category.type">
                        <td>{{ category.type }}</td>
                        <td>{{ category.fee }}</td>
                    </tr>
                </table>
                <br />
                <p><strong>Social Events:</strong></p>

                <p>
                    <span class="event-name">Conference Dinner:{{ ' ' }}</span>
                    <span class="event-price">CHF 100</span>
                </p>
                <br />
                <p><strong>Workshops:</strong></p>
                <p>
                    <span class="event-name">Attending a Workshop:{{ ' ' }}</span>
                    <span class="event-price">CHF 40</span>
                </p>

                <br />
                <p>{{ registrationData.workshops.details }}</p>
                <p>{{ registrationData.socialEvents.details }}</p>
                <br />
                <h4 class="font-bold my-2">Payment Options</h4>
                <p>{{ registrationData.paymentOptions }}</p>
                <br />
                <h4 class="font-bold my-2">Cancellation Policy</h4>
                <p>{{ registrationData.cancellationPolicy }}</p>
            </div>
        </div>
    </section>
</template>

<style scoped>
table {
    width: 100%;
    border-collapse: collapse;
}

th,
td {
    padding: 8px;
    text-align: left;
}
</style>
