<script setup>
import {inject} from 'vue'

const contentData = inject('contentData')
</script>

<template>
    <section class="py-10 bg-gray-50">
        <div class="container px-4 mx-auto">
            <span class="block text-gray-600">{{ contentData.text_footer }}</span>
            <p></p>
        </div>
    </section>
</template>

<style scoped></style>
