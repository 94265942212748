<script setup>
import {inject} from 'vue'
import HeaderInner from '@/components/HeaderInner.vue'

const contentData = inject('contentData')
const contentFileName = process.env.VUE_APP_CONTENT_FILE_NAME
</script>

<template>
    <div
        v-if="contentFileName !== 'content_rn.yml'"
        class="pt-24 sm:pt-34 bg-cover"
        v-bind:style="{'background-image': `url('${contentData.background_image_path}')`}">
        <HeaderInner />
    </div>

    <div v-else class="relative pt-24 sm:pt-34">
        <video autoplay loop muted class="absolute top-0 left-0 w-full h-full object-cover z-0">
            <source :src="`${contentData.background_video_path}`" type="video/mp4" />
        </video>
        <div class="relative container px-4 mx-auto z-10">
            <HeaderInner />
        </div>
    </div>
</template>

<style scoped>
.header-logo-2 {
    height: 2.5vh;
}

.header-logo-1 {
    padding-top: 0.22vh;
    height: 2.5vh;
}
</style>
