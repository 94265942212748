import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import './assets/tailwind.css'
import {createI18n} from 'vue-i18n'
import en from './i18n/locales/en.json'
import contentYmlPHT from '../content/content_pht.yml'
import contentYmlRN from '../content/content_rn.yml'

const messages = {
    en,
}

const i18n = createI18n({
    locale: 'en',
    messages,
})
const contentFileName = process.env.VUE_APP_CONTENT_FILE_NAME // content_rn.yml or content_pht.yml
console.log('Content file name:', contentFileName)
console.log(contentYmlRN)

const app = createApp(App)

const contentData = contentFileName === 'content_rn.yml' ? contentYmlRN : contentYmlPHT
console.log('Parsed content:', contentData)
app.provide('contentData', contentData)

app.use(i18n)
app.use(router)
app.mount('#app')
