<script setup>
import {inject, onMounted, ref} from 'vue'
import axios from 'axios'

const contentData = inject('contentData')

onMounted(async () => {
    await getSpeakers(speakersUrl)
})

const speakersUrl = 'api/authors'
const speakers = ref([])
const backEndURL = process.env.VUE_APP_BACKEND_URL

const getSpeakers = async url => {
    try {
        const res = await axios.get(`${backEndURL}/${url}`)
        speakers.value = res.data
    } catch (err) {
        console.error(err)
    }
}
</script>

<template>
    <section class="py-16 bg-gray-50" id="speakers">
        <div class="container px-4 mx-auto">
            <div class="max-w-3xl mx-auto text-center">
                <h1 class="font-heading text-3xl sm:text-4xl mt-2 mb-6 uppercase">
                    {{ contentData.speakers.title }}
                </h1>
            </div>
            <div class="flex flex-wrap -mx-4 -mb-8 justify-center">
                <div class="w-full sm:w-1/2 xl:w-1/3 px-4 mb-8" v-for="speaker in speakers">
                    <div class="pt-10 pb-8 px-6 text-center bg-white rounded-md">
                        <img
                            class="block mb-8 mx-auto rounded-full object-scale-down h-36 w-38"
                            :src="speaker.image ? speaker.image : require('@/assets/placeholder.jpg')"
                            :alt="speaker.name" />
                        <h4 class="font-heading leading-7 text-lg font-medium">{{ speaker.name }}</h4>
                        <p class="leading-7 text-gray-400 mb-6">
                            {{ speaker.description }}
                        </p>
                        <a :href="speaker.affiliation_href" class="text-blue-500">
                            {{ speaker.affiliation }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped></style>
