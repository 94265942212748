<script setup>
import ScheduleTableInner from '@/components/widgets/ScheduleTableInner'
import {getTimes, filterEvents} from '@/components/helpers'
import {ref, onMounted, inject} from 'vue'
import axios from 'axios'

const contentData = inject('contentData')
const backEndURL = process.env.VUE_APP_BACKEND_URL

onMounted(async () => {
    await getSessions('api/sessions')
})

const props = defineProps(['days', 'times', 'events'])
const needs_to_be_wide = ref(true)
const sessions = ref({})

const getSessions = async url => {
    try {
        const res = await axios.get(`${backEndURL}/${url}`)
        sessions.value = res.data
    } catch (err) {
        console.error(err)
    }
}

const getBackgroundColor = ev => {
    return ev.title.toLowerCase().includes('contributed') && props.contentData.what === 'rn'
        ? tinycolor(ev.agenda_session.color).lighten(20).toString()
        : ev.agenda_session.color
}
</script>

<template>
    <div id="agenda">
        <div v-if="contentData.what !== 'rn'">
            <div :style="{display: 'inline', textAlign: 'left'}" v-for="session in sessions" :key="session">
                <div class="legend-container">
                    <div class="session-legend" :style="{backgroundColor: session.color}"></div>
                    <div class="legend-text">{{ session.name }}</div>
                </div>
            </div>
        </div>
        <div class="table-container">
            <div v-for="(day, index) in days" :key="day" class="ml-4">
                <div class="sm:text-2xl mt-8 mb-8">Day {{ index + 1 }}: {{ day }}</div>
                <div class="scroll">
                    <table>
                        <tbody>
                            <tr v-for="time in times" :key="time">
                                <td
                                    class="text-gray-600 pr-2 pb-8 whitespace-nowrap text-sm font-light small-cell border-b-2 border-dotted"
                                    scope="row"
                                    v-if="getTimes(props.events).includes(`${day} ${time}`)">
                                    {{ time }}
                                </td>

                                <!--                                :style=" props.item.title.toLowerCase().includes('contributed') &&-->
                                <!--                                contentData.what === 'rn' ? {backgroundColor: 'rgba(0, 0, 0, 0.6)'} : {} "-->

                                <td
                                    v-if="getTimes(props.events).includes(`${day} ${time}`)"
                                    v-for="ev of filterEvents(day, time, props.events)"
                                    :style="{
                                        background:
                                            ev.title.includes('Contributed') && contentData.what === 'rn'
                                                ? '#f0f0f0'
                                                : ev.agenda_session.color,
                                        zIndex: ev.title === 'PARALLEL SESSION' ? 10 : 0,
                                    }"
                                    :rowspan="ev.time.length"
                                    :colspan="
                                        filterEvents(day, time, props.events).length === 1 && !ev.parallel
                                            ? 3
                                            : filterEvents(day, time, props.events).length === 2 &&
                                                !ev.title.includes('PARALLEL')
                                              ? 1
                                              : filterEvents(day, time, props.events).length === 2 &&
                                                  ev.title.includes('PARALLEL')
                                                ? 2
                                                : filterEvents(day, time, props.events).length === 3
                                                  ? 1
                                                  : 1
                                    "
                                    :key="`${time} ${ev.name}`"
                                    :class="needs_to_be_wide ? 'big-cell' : 'cell'"
                                    class="text-sm px-6 py-4 whitespace-nowrap border font-medium text-white">
                                    <ScheduleTableInner :item="ev" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.cell {
    width: 50%;
}
.small-cell {
    max-width: 70px;
}
.big-cell {
    min-width: 300px;
}

table {
    max-width: 800px;
    table-layout: fixed;
    border-collapse: collapse;
    margin: 0 15px;
}

.table-container {
    min-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow-x: auto;
    flex-wrap: wrap;
}

.flex-child {
    flex: 1;
}

.session-legend {
    width: 38px;
    height: 20px;
    border: 1px solid #000;
    display: inline-block;
    color: rgba(0, 0, 0, 0);
    margin-right: 1.4rem;
}

.legend-text {
    display: inline-block;
    font-size: 17px;
    margin-right: 0.2rem;
    min-width: 10.6rem;
}

.legend-container {
    margin-bottom: 0.6rem;
}

td {
    word-wrap: break-word;
    white-space: normal;
}

tr:last-child {
    padding-bottom: 15px;
}
tr:first-child {
    padding-top: 15px;
}

.scroll {
    width: 100%;
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
    .scroll {
        overflow-x: scroll;
        width: 350px;
    }
}

.scroll::-webkit-scrollbar {
    margin-top: 2px;
    width: 12px;
}

.scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

.scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
</style>
