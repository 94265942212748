<script setup>
import List from '@/components/widgets/List'
import {inject} from 'vue'
import CallForAbstracts from '@/components/CallForAbstracts.vue'

const contentData = inject('contentData')
const contentFileName = process.env.VUE_APP_CONTENT_FILE_NAME
</script>

<template>
    <section class="imageContainer relative py-16 bg-gray-50 overflow-hidden" id="overview">
        <div class="container px-4 mx-auto mb-6">
            <div
                v-if="contentData.what === 'rn'"
                class="w-full flex flex-wrap justify-around backdrop-blur-3xl backdrop-filter rounded-lg">
                <div class="inline-block">
                    <a
                        href="https://www.snf.ch/en"
                        v-if="contentData.first_logo !== '../assets/rn/SNF_logo.png'">
                        <img src="../assets/banner.png" alt="The logo image of ETHZ" />
                    </a>
                </div>
            </div>

            <div class="max-w-3xl mx-auto text-center mt-6">
                <h1 class="font-heading text-3xl sm:text-4xl mt-6 mb-6 uppercase">
                    {{ contentData.overview.title }}
                </h1>
                <div class="text-left">
                    <span class="text-sm font-semibold uppercase">
                        <b>{{ $t('overview.date') }}:</b>
                    </span>
                    {{ contentData.overview.date }}
                    <span class="text-sm font-semibold uppercase">
                        <b class="ml-5">{{ $t('overview.venue') }}:</b>
                    </span>
                    {{ contentData.overview.venue }}
                </div>
            </div>

            <div class="max-w-3xl mx-auto mt-3" id="temp-registration-and-submission">
                <p
                    class="text-lg leading-8 text-justify"
                    v-for="p in contentData.overview.text"
                    v-html="p"></p>

                <div
                    class="text-lg leading-8 text-justify mt-5"
                    v-if="contentData.overview.newsletter.display">
                    <span class="text-sm font-semibold uppercase">
                        <b>stay up to date</b>
                    </span>
                    <p>
                        <a
                            class="text-cyan-700"
                            href="https://ebpi.lists.uzh.ch/sympa/subscribe/swissrn?previous_action=review"
                            target="_blank">
                            Subscribe to the SwissRN newsletter
                        </a>
                        to stay up to date.
                    </p>
                </div>

                <div
                    class="text-lg leading-8 text-justify mt-5"
                    v-if="contentData.overview.newsletter.display">
                    <span class="text-sm font-semibold uppercase">
                        <b>
                            Presentations from the Swiss Reproducibility Conference 2024 Now Available Online!
                        </b>
                    </span>
                    <p>
                        A big thank you to everyone who participated, presented and engaged in discussions on
                        reproducibility at the inaugural Swiss Reproducibility Conference last June. If you’d
                        like to revisit the insights shared during the event, the presentations are now
                        accessible on
                        <a class="text-cyan-700" href="https://osf.io/f7p5b/" target="_blank">OSF</a>
                        .
                    </p>
                </div>

                <div
                    class="text-lg leading-8 text-justify mt-5"
                    v-if="contentData.overview.registration_and_submission_text.display">
                    <span class="text-sm font-semibold uppercase">
                        <b>registration</b>
                    </span>

                    <p v-for="p in contentData.overview.registration_and_submission_text.text" v-html="p"></p>
                    <p v-if="contentData.what === 'rn'">
                        <a
                            class="text-cyan-700"
                            href="https://ebpi.lists.uzh.ch/sympa/subscribe/swissrn?previous_action=review"
                            target="_blank">
                            Subscribe to the SwissRN newsletter
                        </a>
                        to stay up to date.
                    </p>
                </div>
            </div>
        </div>
        <div class="max-w-3xl mx-auto text-center" v-if="contentData.overview.topics_and_goals.display">
            <h1 class="font-heading text-3xl sm:text-4xl mt-2 mb-6">
                <span class="text-sm font-semibold uppercase">
                    <b>{{ $t('overview.key_topics') }}</b>
                </span>
            </h1>
        </div>
        <div class="relative container px-4 mx-auto mb-10">
            <div class="max-w-4xl mx-auto">
                <div class="flex flex-wrap -mx-4 -mb-8 items-center justify-center">
                    <List :list-entries="contentData.overview.topics_and_goals.list" />
                </div>
            </div>
        </div>
        <!--        <div class="max-w-3xl mx-auto text-center" v-if="contentData.overview.downloads.display">-->
        <!--            <h1 class="font-heading text-3xl sm:text-4xl mt-2 mb-6">-->
        <!--                <span class="text-sm font-semibold uppercase">-->
        <!--                    <b>{{ $t('overview.downloads') }}</b>-->
        <!--                </span>-->
        <!--            </h1>-->
        <!--        </div>-->
        <!--        <div class="relative container px-4 mx-auto mb-10">-->
        <!--            <div class="max-w-4xl mx-auto">-->
        <!--                <div class="flex flex-wrap -mx-4 -mb-8 items-center justify-center">-->
        <!--                    <ul v-for="download of contentData.overview.downloads.list" :key="download.link">-->
        <!--                        <li class="mx-8">-->
        <!--                            <a class="text-cyan-700" :href="download.link" target="_blank">-->
        <!--                                <div class="items-center justify-center">-->
        <!--                                    <img class="mx-auto mb-2" src="https://flaticons.net/icon.php?slug_category=application&slug_icon=document-download-02&icon_size=32&icon_color=000000&icon_flip=&icon_rotate=0"/>-->
        <!--                                    {{ download.title }}-->
        <!--                                </div>-->
        <!--                            </a>-->
        <!--                        </li>-->
        <!--                    </ul>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </div>-->
    </section>
    <div>
        <section
            class="imageContainer relative py-1 bg-gray-50 overflow-hidden"
            v-for="i in contentData.overview.other">
            <div v-if="i.display">
                <div class="max-w-3xl mx-auto text-center">
                    <h1 class="font-heading text-3xl sm:text-4xl mb-6">
                        <span class="text-sm font-semibold uppercase">
                            <b>{{ i.title }}</b>
                        </span>
                    </h1>
                </div>

                <div class="max-w-3xl mx-auto text-center mt-5 px-4">
                    <p class="text-lg leading-8 text-justify" v-for="p in i.text">
                        {{ p }}
                    </p>
                </div>
            </div>
        </section>
    </div>
</template>

<style scoped>
.imageContainer {
    background-image: url('../assets/images/bg.png');
}
</style>
