<script setup>
import {agendaDetails} from '@/components/data'
import {inject, onMounted, ref} from 'vue'
import axios from 'axios'
const placeholder = 'placeholder.jpg'

const contentData = inject('contentData')

const backEndURL = process.env.VUE_APP_BACKEND_URL

onMounted(async () => {
    await getSessions('api/sessions')
})

const sessions = ref([])

const getSessions = async url => {
    try {
        const res = await axios.get(`${backEndURL}/${url}`)
        sessions.value = res.data
    } catch (err) {
        console.error(err)
    }
}

const sortSessionsByOrder = sessions => {
    return sessions.sort((a, b) => {
        return a.order - b.order
    })
}
</script>

<template>
    <section class="py-12" id="sessions" v-if="sessions">
        <div class="max-w-xl mx-auto text-center">
            <h1 class="font-heading text-3xl sm:text-4xl mt-2 mb-4 uppercase">Sessions</h1>
        </div>
        <div
            class="container px-4 mx-auto"
            v-for="item in sortSessionsByOrder(sessions.filter(s => s.display))"
            :id="item.name">
            <div class="py-8 px-0 sm:px-10 rounded-md my-6 mx-auto max-w-1/3">
                <div class="flex flex-col items-center mx-0 bg-green-50 px-0 py-8 max-w-1/3">
                    <div class="w-full lg:w-3/4 px-4 mb-8 lg:mb-8 flex mt-4 items-center justify-center">
                        <img
                            class="block w-1/3 h-auto mx-auto rounded object-cover object-top"
                            :src="item.image ? item.image : contentData.placeholder"
                            alt="" />
                    </div>
                    <div class="w-4/5 px-1 flex flex-col justify-center">
                        <h4 class="font-heading text-xl md:text-2xl mt-2 mb-4">
                            {{ item.name }}
                        </h4>
                        <p class="text-left text-lg leading-8 mb-4">
                            {{ item.description }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped></style>
