<script setup>
import {ref, onMounted} from 'vue'
import axios from 'axios'
const sessions = ref([])

const backEndURL = process.env.VUE_APP_BACKEND_URL
const getSessions = async url => {
    try {
        const res = await axios.get(`${backEndURL}/${url}`)
        sessions.value = res.data
    } catch (err) {
        console.error(err)
    }
}

onMounted(async () => {
    await getSessions('api/sessions')
})
const downloadPDF = () => {
    window.open('./assets/call2.pdf', '_blank')
}
</script>

<template>
    <section class="py-16 bg-gray-700 text-white">
        <div class="container px-4 mx-auto">
            <div class="max-w-3xl mx-auto mt-5">
                <div class="text-lg leading-8 text-justify">
                    <div class="px-4 mx-auto" id="call">
                        <h1 class="font-heading text-3xl sm:text-4xl mb-6 uppercase">
                            Call for Abstracts and Nominations
                        </h1>
                        <p class="mb-5">
                            The Swiss National Science Foundation and the Swiss Reproducibility Network
                            invited to submit abstracts for the Swiss Reproducibility Conference, taking place
                            June, 10 - 11, 2024 in Zürich, as well as nominations for the Swiss
                            Reproducibility Award 2024.
                        </p>

                        <span class="text-sm font-semibold uppercase mt-10">
                            <b>Call for abstracts for contributed talks and posters</b>
                        </span>
                        <p>
                            Submissions for contributed talks and posters were welcome in all areas related to
                            the 3 session topics of the conference:
                        </p>
                        <ul class="list-disc ml-10">
                            <li
                                v-for="session in sessions.filter(
                                    session =>
                                        !['Tutorials & Workshops', 'Other', 'Misc.', 'General'].includes(
                                            session.name
                                        )
                                )"
                                class="cursor-pointer text-cyan-700"
                                :key="'abstract_' + ' ' + session.name">
                                <a :href="`#${session.name}`">{{ session.name }}</a>
                            </li>
                        </ul>
                        <p>
                            The call has ended. Notifications of acceptance or rejection have been
                            communicated.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped></style>
