import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import InfoView from '../views/InfoView.vue'
import AbstractView from '@/views/AbstractView.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
    },
    {
        path: '/info/:link',
        name: 'info',
        component: InfoView,
    },
    {
        path: '/abstract/:link',
        component: AbstractView,
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

export default router
