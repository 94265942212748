<script setup>
import {inject} from 'vue'

const contentData = inject('contentData')

const downloadPDF = () => {
    window.open('../assets/award2.pdf', '_blank')
}
</script>

<template>
    <section class="py-16 bg-gray-700" id="award">
        <div class="container px-4 mx-auto">
            <h1 class="text-white font-heading text-3xl sm:text-4xl mt-2 mb-6 uppercase">
                {{ contentData.award.title }}
            </h1>

            <div class="max-w-3xl mx-auto mt-8" v-for="item in contentData.award.sections">
                <span class="text-sm font-semibold uppercase text-white">
                    <b>{{ item.subtitle }}</b>
                </span>
                <p v-for="p in item.text" class="mt-2 text-gray-300 text-lg leading-8 text-justify" :key="p">
                    {{ p }}
                </p>
            </div>
            <div class="max-w-3xl mx-auto mt-8">
                <p class="mt-2 text-gray-300 text-lg leading-8 text-justify">
                    The winners of the Swiss Reproducibility Awards 2024 were announced at the Swiss
                    Reproducibility Conference at the award ceremony. The prize money of CHF 3000 for each of
                    the Early Career Researcher Awards and the CHF 6000 for the recipients of the team Award
                    was sponsored by the SNSF.
                </p>

                <p class="mt-2 text-gray-300 text-lg leading-8 text-justify">
                    The Early Career Researchers Awards were presented for the second time. The following
                    applicants won this edition 2024:
                </p>

                <p class="mt-2 text-gray-300 text-lg leading-8 text-justify">
                    <b>Category Biology and Medicine</b>
                    : Virginia Chiocchia, PhD from the University of Bern for her tool ROB-MEN, a
                    user-friendly web application to assess the Risk Of Bias due to Missing Evidence in
                    Network meta-analysis.
                </p>

                <p class="mt-2 text-gray-300 text-lg leading-8 text-justify">
                    <b>Category Humanities and Social Sciences</b>
                    : Dr. Martin Constant from the University of Geneva for his published manuscript "A
                    multilab investigation into the N2pc as an indicator of attentional selectivity: Direct
                    replication of Eimer (1996)".
                </p>

                <p class="mt-2 text-gray-300 text-lg leading-8 text-justify">
                    <b>Category Mathematics, Natural and Engineering Sciences</b>
                    : Igor Tomić, PhD from EPFL his paper "Advancing Seismic Research: Transparent Data
                    Sharing of AIMS Shake-Table Tests for Unreinforced Masonry Aggregates".
                </p>

                <p class="mt-2 text-gray-300 text-lg leading-8 text-justify">
                    The Reproducibility Award for teams was presented for the first time. The winners were
                    Yvonne Fuchs and Dominic Weber from the University of Basel for their software
                    <span>
                        <a class="cursor-pointer text-blue-600">www.transcriptiones.ch</a>
                        .
                    </span>
                </p>

                <p class="mt-2 text-gray-300 text-lg leading-8 text-justify">
                    SwissRN and the SNSF would like to congratulate all award winners and thank all
                    participants for their submissions.
                </p>
            </div>
            <!--            <div class="max-w-3xl mx-auto mt-8">-->
            <!--                <p class="mt-2 text-gray-300 text-lg leading-8 text-justify">-->
            <!--                    All details regarding eligibility, selection criteria, submission process etc. can be-->
            <!--                    found in the-->
            <!--                    <a @click="downloadPDF" class="cursor-pointer text-blue-300">call document</a>-->
            <!--                    .-->
            <!--                </p>-->
            <!--            </div>-->
            <div class="max-w-3xl mx-auto mt-8">
                <span class="text-sm font-semibold uppercase text-white">
                    <b>Award Committee</b>
                </span>
                <p class="mt-2 text-gray-300 text-lg leading-8 text-justify">
                    <a
                        class="cursor-pointer text-blue-300"
                        href="https://www.ispm.unibe.ch/about_us/staff/egger_matthias/index_eng.html"
                        target="_blank">
                        Matthias Egger,
                    </a>

                    <a
                        class="cursor-pointer text-blue-300"
                        href="https://www.snf.ch/de/MVdVqAFEbtDZdvPW/seite/ueberuns/organisation/leitung"
                        target="_blank">
                        President of the National Research Council
                    </a>
                    and Head of Research Group,
                    <a
                        class="cursor-pointer text-blue-300"
                        href="https://www.ispm.unibe.ch/about_us/staff/egger_matthias/index_eng.html"
                        target="_blank">
                        Institute of Social and Preventive Medicine
                    </a>
                </p>
                <p class="mt-2 text-gray-300 text-lg leading-8 text-justify">
                    <a
                        class="cursor-pointer text-blue-300"
                        href="https://www.bristol.ac.uk/people/?search=Faculty+of+Life+Sciences%2FPsychological+Science"
                        target="_blank">
                        Marcus Munafo,
                    </a>
                    School of Psychological Science
                    <a class="cursor-pointer text-blue-300" href="https://www.bristol.ac.uk/" target="_blank">
                        University of Bristol
                    </a>
                </p>
                <p class="mt-2 text-gray-300 text-lg leading-8 text-justify">
                    <a
                        class="cursor-pointer text-blue-300"
                        href="https://www.nc3rs.org.uk/dr-nathalie-percie-du-sert"
                        target="_blank">
                        Nathalie Percie Du Sert,
                    </a>
                    Head of Experimental Design and Reporting,
                    <a class="cursor-pointer text-blue-300" href="https://www.nc3rs.org.uk/">
                        National Centre for the Replacement, Refinement and Reduction of Animals in Research
                    </a>
                </p>
                <p class="mt-2 text-gray-300 text-lg leading-8 text-justify">
                    <a
                        class="cursor-pointer text-blue-300"
                        href="https://twitter.com/stephensenn"
                        target="_blank">
                        Stephen Senn,
                    </a>
                    Former head of Competence Centre for Methodology and Statistics at the Luxembourg
                    Institute of Health
                </p>
            </div>

            <div class="max-w-3xl mx-auto mt-8">
                <span class="text-sm font-semibold uppercase text-white">
                    <b>Swiss Reproducibility Award 2022</b>
                </span>
                <div class="mt-2 text-gray-300 text-lg leading-8 text-justify">
                    The first edition of the Swiss Reproducibility Award was presented 2022 by SwissRN. The
                    winners of the award 2022 were:
                </div>
                <div class="mt-2 text-gray-300 text-lg leading-8 text-justify">
                    <p>
                        Category Biology and Medicine:
                        <b>Mathieu Seppey, University of Geneva</b>
                    </p>
                    <p>
                        Category Humanities and Social Science:
                        <b>Caro Hautekiet and Tobia Spampatti, University of Geneva</b>
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped></style>
