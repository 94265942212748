<script setup>
import ScheduleTable from '@/components/widgets/ScheduleTable'
import {inject, onMounted, ref} from 'vue'
import axios from 'axios'
import {useRouter} from 'vue-router'
const router = useRouter()

const contentData = inject('contentData')

const events = ref([])
const days = ref([])
const times = ref([])

const daysURL = 'api/days'
const timesURL = 'api/times'
const eventsURL = 'api/events'

const backEndURL = process.env.VUE_APP_BACKEND_URL

onMounted(async () => {
    await parseData()
})

const getData = async url => {
    try {
        const res = await axios.get(`${backEndURL}/${url}`)
        return res.data
    } catch (err) {
        console.error(err)
    }
}

const parseData = async () => {
    const _days = await getData(daysURL)
    days.value = _days.map(day => day.formatted_date)

    const _times = await getData(timesURL)
    times.value = _times.map(time => time.formatted_time)

    const _events = await getData(eventsURL)
    events.value = _events.map(({time_slots, day, author, ...ev}) => ({
        ...ev,
        ref: `${time_slots[0].formatted_time} ${day.formatted_date}`,
        time: time_slots.map(({formatted_time}) => formatted_time),
        day: day.formatted_date,
        author: author.map(a => `${a.name}, ${a.affiliation}`),
    }))
}

const handleClick = item => {
    if (item.link) {
        router.push(`/info/${item.link}`)
    }
}
</script>

<template>
    <section class="pb-16 bg-white bg-contain bg-no-repeat bg-center" id="scheduler">
        <div class="container px-2 mx-auto flex items-center justify-center">
            <ScheduleTable :days="days" :times="times" :events="events" />
        </div>
    </section>
    <div v-if="contentData.scheduler_details.display">
        <section
            :key="item.title"
            class="pb-5 bg-white bg-contain bg-no-repeat bg-center"
            v-for="item in contentData.scheduler_details.items">
            <div class="max-w-3xl mx-auto text-center">
                <h1 class="font-heading text-3xl sm:text-4xl mb-6">
                    <span class="text-sm font-semibold uppercase">
                        <b>{{ item.title }}</b>
                    </span>
                </h1>
            </div>

            <div class="max-w-3xl mx-auto text-center mt-5 px-4" id="workshops">
                <p v-if="item.title === 'Conference dinner'">
                    The conference dinner will be held on Monday, August 26, 2024 from 18:30 – 22:00 at
                    Zunfthaus zur Safran, Limmatquai 54, 8001 Zürich. It will feature live music and a guest
                    talk of
                    <a
                        href="https://cifs.dk/people/bogi-eliasen/"
                        target="_blank"
                        class="cursor-pointer text-blue-600">
                        Bogi Eliasen
                    </a>
                    who will enrich our fantasy about the future of health.
                </p>
                <p v-else class="text-lg text-center leading-8 text-justify" v-for="p in item.text">
                    {{ p }}
                </p>
            </div>
        </section>

        <div v-if="contentData.scheduler_details.details" class="mb-12">
            <ul class="list-disc list-inside space-y-2">
                <li
                    @click="handleClick(workshop)"
                    class="p-2 rounded-md"
                    v-for="(workshop, index) in contentData.scheduler_details.details"
                    :key="workshop.name">
                    <h4>Workshop {{ `${index + 1}` }}</h4>
                    <p class="font-semibold text-md text-blue-600 cursor-pointer">{{ workshop.name }}</p>
                    <p class="text-gray-600">{{ workshop.venue }}</p>
                </li>
            </ul>
        </div>

        <!--        <div class="max-w-3xl mx-auto text-center px-4">-->
        <!--            <p class="text-lg leading-8 text-justify">-->
        <!--                Would you like to host such an event? If so, submit your-->
        <!--                <a href="https://ethzurich.eventsair.com/pht-2024/submission" class="link">abstract</a>-->
        <!--                to apply.-->
        <!--            </p>-->
        <!--        </div>-->
    </div>
</template>

<style scoped>
.link {
    cursor: pointer;
    color: #007bff;
    text-decoration: none;
}

.text-center {
    text-align: center;
    margin: 0 auto;
}
</style>
