<script setup>
import {inject} from 'vue'

const contentData = inject('contentData')
</script>

<template>
    <section class="py-16 bg-gray-700" id="contact" v-if="contentData.contact.display">
        <div class="container px-4 mx-auto">
            <h1 class="text-white font-heading text-3xl sm:text-4xl mt-2 mb-6 uppercase">
                {{ contentData.contact.title }}
            </h1>
            <span class="text-white font-heading mt-2 mb-10" v-html="contentData.contact.subtitle"></span>

            <div class="max-w-3xl mx-auto mt-8">
                <p
                    v-for="p in contentData.contact.text"
                    class="mt-2 text-gray-300 text-lg leading-8 text-justify"
                    :key="p">
                    {{ p }}
                </p>
            </div>
            <div
                class="flex justify-center items-center max-w-3xl mx-auto mt-8"
                v-if="contentData.contact.show_icons">
                <a
                    class="flex items-center justify-center w-12 h-12 mr-2 rounded-full bg-gray-800 hover:bg-gray-700"
                    :href="
                        contentData.what !== 'rn'
                            ? 'https://twitter.com/nexus_pht?lang=en'
                            : 'https://twitter.com/SwissRN'
                    "
                    target="_blank">
                    <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M14.5455 4.09728C14.0905 4.29892 13.6022 4.43566 13.0892 4.49671C13.613 4.18304 14.014 3.6855 14.204 3.09447C13.7127 3.38496 13.1703 3.59589 12.5924 3.71023C12.1296 3.21655 11.4714 2.90906 10.7413 2.90906C9.34059 2.90906 8.2049 4.04475 8.2049 5.44467C8.2049 5.64322 8.2273 5.83714 8.27057 6.02257C6.16298 5.91671 4.29411 4.90696 3.0433 3.37259C2.82465 3.74653 2.70027 4.18225 2.70027 4.64736C2.70027 5.52734 3.14837 6.30379 3.82825 6.75805C3.41259 6.74415 3.02166 6.62981 2.67942 6.43974V6.47142C2.67942 7.69983 3.55399 8.72504 4.71362 8.95837C4.50116 9.01554 4.27712 9.04722 4.04534 9.04722C3.88156 9.04722 3.72318 9.031 3.56788 9.00009C3.89081 10.0083 4.8272 10.7415 5.93663 10.7616C5.06902 11.4415 3.97504 11.8455 2.78681 11.8455C2.58209 11.8455 2.38043 11.8332 2.18188 11.8108C3.30445 12.5316 4.63716 12.9519 6.06952 12.9519C10.7351 12.9519 13.2854 9.0874 13.2854 5.73595L13.2769 5.4076C13.7752 5.05219 14.2063 4.60564 14.5455 4.09728Z"
                            fill="white"></path>
                        <mask
                            id="mask0_37_113"
                            style="mask-type: alpha"
                            maskUnits="userSpaceOnUse"
                            x="2"
                            y="2"
                            width="13"
                            height="11">
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M14.5455 4.09728C14.0905 4.29892 13.6022 4.43566 13.0892 4.49671C13.613 4.18304 14.014 3.6855 14.204 3.09447C13.7127 3.38496 13.1703 3.59589 12.5924 3.71023C12.1296 3.21655 11.4714 2.90906 10.7413 2.90906C9.34059 2.90906 8.2049 4.04475 8.2049 5.44467C8.2049 5.64322 8.2273 5.83714 8.27057 6.02257C6.16298 5.91671 4.29411 4.90696 3.0433 3.37259C2.82465 3.74653 2.70027 4.18225 2.70027 4.64736C2.70027 5.52734 3.14837 6.30379 3.82825 6.75805C3.41259 6.74415 3.02166 6.62981 2.67942 6.43974V6.47142C2.67942 7.69983 3.55399 8.72504 4.71362 8.95837C4.50116 9.01554 4.27712 9.04722 4.04534 9.04722C3.88156 9.04722 3.72318 9.031 3.56788 9.00009C3.89081 10.0083 4.8272 10.7415 5.93663 10.7616C5.06902 11.4415 3.97504 11.8455 2.78681 11.8455C2.58209 11.8455 2.38043 11.8332 2.18188 11.8108C3.30445 12.5316 4.63716 12.9519 6.06952 12.9519C10.7351 12.9519 13.2854 9.0874 13.2854 5.73595L13.2769 5.4076C13.7752 5.05219 14.2063 4.60564 14.5455 4.09728Z"
                                fill="white"></path>
                        </mask>
                    </svg>
                </a>

                <a
                    class="flex items-center justify-center w-12 h-12 bg-gray-800 hover:bg-gray-700"
                    :href="
                        contentData.what !== 'rn' ? ' https://www.nexus.ethz.ch/' : 'https://www.swissrn.org/'
                    "
                    target="_blank">
                    <svg
                        color="white"
                        class="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                    </svg>
                </a>
            </div>
        </div>
    </section>
</template>

<style>
.link {
    color: #0ab971;
}

.link:hover {
    color: #5569ef;
}
</style>
