<script setup>
import axios from 'axios'
import {onMounted, ref} from 'vue'
import {useRoute} from 'vue-router'
import NavBar from '@/components/NavBar'

const workshop = ref(null)
const workshopsURL = 'api/workshops'

const backEndURL = process.env.VUE_APP_BACKEND_URL

const route = useRoute()

onMounted(async () => {
    await parseData()
})

const getData = async url => {
    try {
        const res = await axios.get(`${backEndURL}/${url}`)
        return res.data
    } catch (err) {
        console.error(err)
    }
}

const parseData = async () => {
    const _workshops = await getData(workshopsURL)
    const currentRoute = route.params.link
    workshop.value = _workshops.find(({link}) => link === currentRoute)
}
</script>

<template>
    <div>
        <NavBar />
        <section class="py-16" id="registration">
            <div class="container px-4 mx-auto max-w-3xl" v-if="workshop">
                <div class="max-w-3xl mx-auto text-center">
                    <h1 class="font-heading text-3xl sm:text-4xl mt-2 mb-6">
                        {{ workshop.title }}
                    </h1>
                    <!--                    <h3 class="text-blue-600 font-heading text-xl sm:text-3xl mt-2 mb-6">-->
                    <!--                        {{ workshop.topic }}-->
                    <!--                    </h3>-->
                </div>

                <div class="text-justify" v-if="workshop.description" v-html="workshop.description"></div>
            </div>
        </section>
    </div>
</template>

<style scoped></style>
