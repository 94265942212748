<script setup>
import {inject, onMounted, ref} from 'vue'
import axios from 'axios'
const contentData = inject('contentData')

const sessions = ref([])

onMounted(async () => {
    await getSessions('api/sessions')
})
const backEndURL = process.env.VUE_APP_BACKEND_URL

const getSessions = async url => {
    try {
        const res = await axios.get(`${backEndURL}/${url}`)
        sessions.value = res.data
    } catch (err) {
        console.error(err)
    }
}
</script>

<template>
    <section class="py-16 bg-white bg-contain bg-no-repeat bg-center" id="submission">
        <div class="container px-4 mx-auto">
            <div class="max-w-3xl mx-auto text-center">
                <h1 class="font-heading text-3xl sm:text-4xl mt-2 mb-6">{{ $t('submission.title') }}</h1>
                <div class="text-left">
                    <b>{{ $t('submission.deadline') }}</b>
                    <p>Workshop: 31 January 2024</p>
                    <p>Contributed Talk: 30 April 2024</p>
                    <p>Posters: 10 May 2024</p>
                </div>
            </div>

            <div class="max-w-3xl mx-auto mt-3">
                <p class="text-lg leading-8 text-justify mt-6">
                    NEXUS invited to submit abstracts for workshops, contributed talks, and/or posters.
                </p>
                <p class="text-lg leading-8 text-justify mt-6">
                    Submissions for contributed talks were welcome in all areas related to the 4 session
                    topics of the conference:
                </p>

                <div class="max-w-3xl mx-auto text-left mt-4 text-lg leading-8">
                    <ul class="list-disc ml-4">
                        <li v-for="item in sessions.filter(s => s.display)">{{ item.name }}</li>
                    </ul>
                </div>

                <p class="text-lg leading-8 text-justify mt-6">
                    The call has ended. Notifications of acceptance or rejection will be sent by June 7th
                    2024.
                </p>
            </div>

            <!--            <div class="max-w-3xl mx-auto text-center mt-10">-->
            <!--                <a-->
            <!--                    target="_blank"-->
            <!--                    class="inline-block py-4 px-6 text-center font-heading font-medium text-base text-white bg-blue-800 hover:bg-green-600 rounded-sm transition duration-200"-->
            <!--                    href="https://ethzurich.eventsair.com/pht-2024/submission">-->
            <!--                    {{ $t('submission.submitBtn') }}-->
            <!--                </a>-->
            <!--            </div>-->
        </div>
    </section>
</template>

<style scoped></style>
