<script setup>
import {inject, onMounted, ref} from 'vue'
import axios from 'axios'
import {booth_info, booth_packages, sponsorship_options} from '@/components/data'
import SponsorsOptionsPht from '@/components/SponsorsOptionsPht.vue'

onMounted(async () => {
    await getSponsors(sponsorsUrl)
})

const sponsorsUrl = 'api/sponsors'
const sponsors = ref([])
const backEndURL = process.env.VUE_APP_BACKEND_URL
const contentData = inject('contentData')

const getSponsors = async url => {
    try {
        const res = await axios.get(`${backEndURL}/${url}`)
        sponsors.value = res.data
    } catch (err) {
        console.error(err)
    }
}

const filterSponsors = (sponsors, main, apero = false) => {
    let _sponsors = sponsors.filter(sponsor => sponsor.display === true)
    if (!apero) {
        return _sponsors.filter(sponsor => (main ? sponsor.main : !sponsor.main && !sponsor.apero))
    } else {
        return _sponsors.filter(sponsor => sponsor.apero)
    }
}
const linkTo = href => {
    window.open(href, '_blank')
}
</script>

<template>
    <section id="sponsors" class="py-16">
        <div class="container px-4 mx-auto">
            <div class="max-w-3xl mx-auto text-center">
                <h1
                    class="font-heading text-3xl sm:text-4xl mt-2 mb-6"
                    v-if="filterSponsors(sponsors, true).length > 0">
                    {{ $t('sponsors.title_main') }}
                </h1>
            </div>

            <div v-if="contentData.sponsors.actual.display">
                <div class="flex flex-col md:flex-row my-4" v-for="sponsor in filterSponsors(sponsors, true)">
                    <img
                        @click="linkTo(sponsor.href)"
                        :src="sponsor.image ? sponsor.image : require('../assets/placeholder.jpg')"
                        class="cursor-pointer block mb-8 mx-auto md:w-1/3 sm:w-1/6 object-scale-down h-75 w-97" />

                    <p class="px-4 py-2 md:w-1/2 text-left">
                        {{ sponsor.description }}
                    </p>
                </div>

                <div
                    class="max-w-3xl mx-auto text-center"
                    v-if="filterSponsors(sponsors, false, true).length > 0">
                    <h1 class="font-heading text-3xl sm:text-4xl mt-2">
                        {{ $t('sponsors.title_apero') }}
                    </h1>
                </div>

                <div class="flex flex-wrap -mx-4 -mb-8 justify-center align-center">
                    <div
                        class="w-60 sm:w-1/8 xl:w-1/4 px-4 mb-2 mt-8 xl:ml-4 xl:mr-4"
                        v-for="sponsor in filterSponsors(sponsors, false, true)">
                        <a :href="sponsor.href" target="_blank">
                            <img
                                class="block mb-12 mx-auto object-scale-down h-36 w-38"
                                :src="sponsor.image ? sponsor.image : require('../assets/placeholder.jpg')"
                                :alt="sponsor.name" />
                        </a>
                    </div>
                </div>

                <div class="max-w-3xl mx-auto text-center" v-if="filterSponsors(sponsors, false).length > 0">
                    <h1 class="font-heading text-3xl sm:text-4xl mt-2 mb-6">
                        {{ $t('sponsors.title_simple') }}
                    </h1>
                </div>

                <div class="flex flex-wrap -mx-4 -mb-8 justify-between align-center">
                    <div
                        class="w-60 sm:w-1/8 xl:w-1/4 px-4 mb-2 mt-8 xl:ml-4 xl:mr-4"
                        v-for="sponsor in filterSponsors(sponsors, false)">
                        <a :href="sponsor.href" target="_blank">
                            <img
                                class="block mb-8 mx-auto object-scale-down h-36 w-38"
                                :src="sponsor.image ? sponsor.image : require('../assets/placeholder.jpg')"
                                :alt="sponsor.name" />
                        </a>
                    </div>
                </div>
            </div>

            <div
                class="container mx-auto"
                v-if="contentData.sponsors.options.display && contentData.what === 'pht'">
                <div class="max-w-3xl mx-auto text-center mt-12">
                    <h1 class="font-heading text-3xl sm:text-4xl mt-2 mb-6">
                        {{ $t('sponsors.options') }}
                    </h1>
                </div>
                <SponsorsOptionsPht v-if="contentData.what === 'pht'" />
            </div>
        </div>
    </section>
</template>

<style scoped></style>
