<script setup>
import {inject, onMounted} from 'vue'
import {useRouter} from 'vue-router'

const router = useRouter()

const contentData = inject('contentData')

onMounted(() => {
    addToggleMenu()
})

const addToggleMenu = () => {
    document.addEventListener('DOMContentLoaded', function () {
        // open
        const burger = document.querySelectorAll('.navbar-burger')
        const menu = document.querySelectorAll('.navbar-menu')

        if (burger.length && menu.length) {
            for (let i = 0; i < burger.length; i++) {
                burger[i].addEventListener('click', function () {
                    for (let j = 0; j < menu.length; j++) {
                        menu[j].classList.toggle('hidden')
                    }
                })
            }
        }
        // close
        const backdrop = document.querySelectorAll('.navbar-backdrop')
        if (backdrop.length) {
            for (let i = 0; i < backdrop.length; i++) {
                backdrop[i].addEventListener('click', function () {
                    for (let j = 0; j < menu.length; j++) {
                        menu[j].classList.toggle('hidden')
                    }
                })
            }
        }
    })
}
const contentFileName = process.env.VUE_APP_CONTENT_FILE_NAME
//"../assets/pht/images/nexus_logo.png"

// function to push to the main page with a hash link to a page section like /@submission
const handleNavClick = href => {
    router.push(`/${href}`)
}
</script>

<template>
    <nav class="py-2 bg-white top-0 z-50">
        <div class="container mx-auto">
            <div class="relative flex items-center justify-between">
                <div class="w-auto">
                    <a class="inline-block" href="#">
                        <img
                            :src="contentData.left_logo"
                            alt="The logo image of Nexus"
                            width="120"
                            height="40" />
                    </a>
                </div>
                <div
                    class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 hidden xl:block">
                    <ul class="flex items-center">
                        <li
                            class="font-heading mr-12 text-base"
                            v-for="link of contentData.nav.items.filter(i => i.display)"
                            :key="link.name">
                            <a class="hover:text-black" :href="link.href" @click="handleNavClick(link.href)">
                                {{ link.name }}
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="w-auto hidden xl:block" v-if="contentData.what !== 'pht'">
                    <a
                        :class="contentData.what === 'rn' ? 'bg-green-500' : 'bg-red-900'"
                        class="inline-block py-4 px-8 font-heading font-medium text-base text-white border hover:border-green-600 rounded-sm transition duration-200"
                        target="_blank"
                        :href="
                            contentData.what === 'rn'
                                ? '#contact'
                                : 'https://ethzurich.eventsair.com/pht-2024/registration/Site/Register'
                        ">
                        {{ contentData.what === 'rn' ? 'Contact' : 'Register' }}
                    </a>
                </div>
                <div class="w-auto xl:hidden">
                    <a
                        ref="navbar-burger"
                        class="navbar-burger inline-flex w-14 h-14 justify-center items-center bg-gray-500 hover:bg-gray-600 rounded-full"
                        href="#">
                        <svg
                            width="20"
                            height="10"
                            viewBox="0 0 20 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M19 9H1M19 1H1"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"></path>
                        </svg>
                    </a>
                </div>
            </div>
        </div>
    </nav>
    <div ref="navbar-menu" class="hidden navbar-menu fixed top-0 left-0 bottom-0 w-5/6 max-w-sm z-50">
        <div
            class="navbar-backdrop fixed inset-0 backdrop-blur-xl backdrop-filter bg-gray-900 bg-opacity-80"></div>
        <nav class="relative pt-7 pb-8 bg-white h-full overflow-y-auto">
            <div class="flex flex-col px-6 h-full">
                <ul class="w-full mb-auto pb-16">
                    <li v-for="link of contentData.nav.items" :key="link.name + link.href">
                        <a
                            v-if="link.display"
                            class="font-heading block text-base font-medium py-4 px-6 hover:bg-green-50 rounded-sm"
                            :href="link.href">
                            {{ link.name }}
                        </a>
                    </li>
                </ul>
                <div class="w-full"></div>
            </div>
        </nav>
    </div>
</template>

<style scoped></style>
